<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-12 layout-spacing">
          <div class="row" v-if="loading">
            <div class="p-2 text-center col-12">
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="67%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="82%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="75%"></b-skeleton>
                <b-skeleton animation="wave" width="92%"></b-skeleton>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="74%"></b-skeleton>
                <b-skeleton animation="wave" width="89%"></b-skeleton>
                <b-skeleton type="button"></b-skeleton>
              </b-card>
            </div>
          </div>

          <div class="row" v-else>
            <div
              class="col-12 col-md-6 col-lg-4"
              v-for="client in clients"
              :key="client.id"
            >
              <div class="card component-card_2">
                <div class="text-center">
                  <img
                    :src="`${gcurl}/clients/${client.avatar}`"
                    class="card-img-top"
                    style="width: 90%"
                    alt="widget-card-2"
                  />
                </div>
                <div class="card-body">
                  <div class="text-center">
                    <h2 class="card-title">{{ client.name }}</h2>
                  </div>
                  <div>
                    <div class="text-center">
                      <b-button
                        v-b-toggle="`collapse-${client.id}`"
                        variant="outline-dark"
                        class="text-center"
                        ><i class="fa fa-info-circle"></i
                      ></b-button>
                      <b-collapse :id="`collapse-${client.id}`" class="mt-2">
                        <ul
                          class="list-group list-group-icons-meta text-center"
                        >
                          <li class="list-group-item list-group-item-action">
                            <div class="media">
                              <div class="d-flex mr-3">
                                <i class="fa fa-envelope"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="tx-inverse">
                                  {{ client.email || "" }}
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item list-group-item-action">
                            <div class="media">
                              <div class="d-flex mr-3">
                                <i class="fa fa-phone"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="tx-inverse">
                                  {{ client.phone || "" }}
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item list-group-item-action">
                            <div class="media">
                              <div class="d-flex mr-3">
                                <i class="fa fa-map-marker"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="tx-inverse">
                                  {{ client.location || "" }}
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item list-group-item-action">
                            <div class="media">
                              <div class="d-flex mr-3">
                                <i class="fa fa-users"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="tx-inverse">
                                  Members :
                                  {{ formatComma(client.users_count) || 0 }}
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item list-group-item-action">
                            <div class="media">
                              <div class="d-flex mr-3">
                                <i class="fa fa-calendar-check"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="tx-inverse">
                                  Visits:
                                  {{ formatComma(client.visits_count) || 0 }}
                                </h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </b-collapse>
                    </div>
                  </div>
                  <div></div>
                  <router-link
                    :to="`/corportate/clients/members/${client.id}`"
                    class="btn btn-success btn-block mt-2 mb-3"
                    >Members <i class="fa fa-arrow-alt-circle-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gerohttp } from "@/utils/gerocarehttp";
import { geroendpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";

export default {
  name: "CorporateClients",
  components: {},

  computed: {
    ...mapGetters(["url", "gcurl"]),
  },

  data() {
    return {
      loading: false,
      clients: [],
    };
  },

  mounted() {
    this.fetchClients();
  },

  methods: {
    fetchClients() {
      this.loading = true;
      gerohttp
        .get(geroendpoints.FETCH_CORPORATE_CLIENTS)
        .then((response) => {
          this.loading = false;
          this.clients = response;
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    formatComma(x) {
      return x.toLocaleString();
    },
  },
};
</script>

<style scoped src="../../../assets/css/components/cards/card.css"></style>

<style></style>
